exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-al-accreditation-tsx": () => import("./../../../src/pages/al/accreditation.tsx" /* webpackChunkName: "component---src-pages-al-accreditation-tsx" */),
  "component---src-pages-al-advisory-board-tsx": () => import("./../../../src/pages/al/advisory-board.tsx" /* webpackChunkName: "component---src-pages-al-advisory-board-tsx" */),
  "component---src-pages-al-contact-tsx": () => import("./../../../src/pages/al/contact.tsx" /* webpackChunkName: "component---src-pages-al-contact-tsx" */),
  "component---src-pages-al-dhc-tsx": () => import("./../../../src/pages/al/dhc.tsx" /* webpackChunkName: "component---src-pages-al-dhc-tsx" */),
  "component---src-pages-al-emr-tsx": () => import("./../../../src/pages/al/emr.tsx" /* webpackChunkName: "component---src-pages-al-emr-tsx" */),
  "component---src-pages-al-health-professionals-tsx": () => import("./../../../src/pages/al/health-professionals.tsx" /* webpackChunkName: "component---src-pages-al-health-professionals-tsx" */),
  "component---src-pages-al-index-tsx": () => import("./../../../src/pages/al/index.tsx" /* webpackChunkName: "component---src-pages-al-index-tsx" */),
  "component---src-pages-al-partners-tsx": () => import("./../../../src/pages/al/partners.tsx" /* webpackChunkName: "component---src-pages-al-partners-tsx" */),
  "component---src-pages-al-patients-tsx": () => import("./../../../src/pages/al/patients.tsx" /* webpackChunkName: "component---src-pages-al-patients-tsx" */),
  "component---src-pages-al-privacy-policy-tsx": () => import("./../../../src/pages/al/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-al-privacy-policy-tsx" */),
  "component---src-pages-al-profile-tsx": () => import("./../../../src/pages/al/profile.tsx" /* webpackChunkName: "component---src-pages-al-profile-tsx" */),
  "component---src-pages-al-so-tsx": () => import("./../../../src/pages/al/so.tsx" /* webpackChunkName: "component---src-pages-al-so-tsx" */),
  "component---src-pages-al-terms-and-conditions-tsx": () => import("./../../../src/pages/al/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-al-terms-and-conditions-tsx" */),
  "component---src-pages-doxy-create-emr-patient-id-tsx": () => import("./../../../src/pages/Doxy/CreateEMR/[patientId].tsx" /* webpackChunkName: "component---src-pages-doxy-create-emr-patient-id-tsx" */),
  "component---src-pages-en-accreditation-tsx": () => import("./../../../src/pages/en/accreditation.tsx" /* webpackChunkName: "component---src-pages-en-accreditation-tsx" */),
  "component---src-pages-en-advisory-board-tsx": () => import("./../../../src/pages/en/advisory-board.tsx" /* webpackChunkName: "component---src-pages-en-advisory-board-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-dhc-tsx": () => import("./../../../src/pages/en/dhc.tsx" /* webpackChunkName: "component---src-pages-en-dhc-tsx" */),
  "component---src-pages-en-emr-tsx": () => import("./../../../src/pages/en/emr.tsx" /* webpackChunkName: "component---src-pages-en-emr-tsx" */),
  "component---src-pages-en-health-professionals-tsx": () => import("./../../../src/pages/en/health-professionals.tsx" /* webpackChunkName: "component---src-pages-en-health-professionals-tsx" */),
  "component---src-pages-en-partners-tsx": () => import("./../../../src/pages/en/partners.tsx" /* webpackChunkName: "component---src-pages-en-partners-tsx" */),
  "component---src-pages-en-patients-tsx": () => import("./../../../src/pages/en/patients.tsx" /* webpackChunkName: "component---src-pages-en-patients-tsx" */),
  "component---src-pages-en-privacy-policy-tsx": () => import("./../../../src/pages/en/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-en-privacy-policy-tsx" */),
  "component---src-pages-en-profile-tsx": () => import("./../../../src/pages/en/profile.tsx" /* webpackChunkName: "component---src-pages-en-profile-tsx" */),
  "component---src-pages-en-so-tsx": () => import("./../../../src/pages/en/so.tsx" /* webpackChunkName: "component---src-pages-en-so-tsx" */),
  "component---src-pages-en-terms-and-conditions-tsx": () => import("./../../../src/pages/en/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-en-terms-and-conditions-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

